import { useState } from 'react';
import './Blog.scss';
import Form from '../../components/Form';
import animation from '../../assets/loader.json';
import { useEffect } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Posts from '../../assets/posts.json';
import { useNavigate } from 'react-router-dom';
import myFile from '../../assets/experts/exp8.png';
import axios from 'axios';

const options = {
  animationData: animation,
  loop: true,
};

const BlogPage = () => {
  const [isFormOpen, setFormOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <div className='page'>
      <div className='contentHolder'>
        <Header onFormClick={() => setFormOpen(true)} />
        <div className='blogContent'>
          <h1 className='blogContentTitle'>Pioneers Blog</h1>
          <div className='blogPosts'>
            {Posts.map(post => (
              <div className='blogPostCard' onClick={() => navigate(`/blog/${post.id}`)}>
                <div className='blogPostCardImage'>
                  <img src={post.image_xs} />
                </div>
                <span className='blogPostCardTitle'>{post.name}</span>
                <span className='blogPostCardDescription'>{post.description}</span>
              </div>
            ))}
          </div>
        </div>
        <Footer />
      </div>
      {isFormOpen && <Form onClose={() => setFormOpen(false)} />}
    </div>
  );
};

export default BlogPage;
